import { render, staticRenderFns } from "./yesMonacoEdit.vue?vue&type=template&id=c20ce4d0&"
import script from "./yesMonacoEdit.vue?vue&type=script&lang=js&"
export * from "./yesMonacoEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports